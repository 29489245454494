<script setup>
import Header from "@/components/header.vue";
import Footer from "@/components/footer.vue";
import ErrorComp from "@/components/errormessage.vue";
import router from "@/router";
import loadercomp from "@/components/loadercomp";
import Swal from "sweetalert2";
import { ref } from "@vue/reactivity";
import getCookie from "@/scripts/utils/getCookie.js";
import { useStore } from "vuex";
const store = useStore(),
    email = ref(null),
    reset_id = ref(null),
    password = ref(null),
    activation_code = ref(null),
    password2 = ref(null);

const handleEmailSubmit = () => {
    store.state.loader = true;
    window.grecaptcha.ready(() => {
        window.grecaptcha.execute(process.env.VUE_APP_reCAPTCHA_SITE_KEY, { action: "password_reset" }).then(async (recaptchaToken) => {
            const response = await fetch("/api/reset", {
                method: "POST",
                body: JSON.stringify({
                    recaptcha_token: recaptchaToken,
                    email: email.value,
                }),
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRFToken": getCookie("csrftoken"),
                },
            });
            const data = await response.json();
            store.state.loader = false;
            if (data.ok) {
                reset_id.value = data.reset_id;
            }
        });
    });
};
const handleActivationCodeSubmit = () => {
    if (password.value != password2.value) {
        store.state.errMsg = "Passwords do not match";
    } else if (activation_code.value.length != 8) {
        store.state.errMsg = "Invalid activation code";
    } else {
        store.state.loader = true;
        window.grecaptcha.ready(() => {
            window.grecaptcha.execute(process.env.VUE_APP_reCAPTCHA_SITE_KEY, { action: "reset_finalize" }).then(async (recaptchaToken) => {
                const response = await fetch(`/api/reset/${reset_id.value}`, {
                    method: "POST",
                    body: JSON.stringify({
                        recaptcha_token: recaptchaToken,
                        reset_secret: activation_code.value,
                        password: password.value,
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        "X-CSRFToken": getCookie("csrftoken"),
                    },
                });

                const data = await response.json();
                store.state.loader = false;
                if (data.ok) {
                    Swal.fire({
                        icon: "success",
                        titleText: "Password Reset Successful",
                        showConfirmButton: false,
                    });
                    router.push({ name: "login" });
                } else {
                    if (data.error == "bad-secret") {
                        store.state.errMsg = "Invalid Activation Code";
                    } else {
                        store.state.errMsg = "An unknown error has occurred";
                    }
                }
            });
        });
    }
};
</script>

<template>
    <div>
        <div class="page_wrap">
            <loadercomp v-if="$store.state.loader" />
            <Header button="btn_mono" :isLight="false" />
            <div v-if="reset_id == null" class="buidl_wrap flex flex_col">
                <h2 class="ta_center">Reset Password</h2>
                <p class="ta_center">Enter your email address to initiate a password reset</p>
                <form action="" class="rivet_login auth_form flex flex_col" @submit.prevent="handleEmailSubmit">
                    <div class="email">
                        <input v-model="email" required type="email" placeholder="Email" />
                    </div>
                    <div class="btn btn_color ta_center">
                        <button type="submit">Submit</button>
                    </div>
                </form>
            </div>
            <div v-else class="buidl_wrap flex flex_col">
                <h2 class="ta_center">Complete Reset</h2>
                <p class="ta_center">Enter the activation code from your email</p>
                <form action="" class="rivet_login auth_form flex flex_col" @submit.prevent="handleActivationCodeSubmit">
                    <ErrorComp :errMsg="$store.state.errMsg" />
                    <div class="email">
                        <input required v-model="activation_code" type="text" placeholder="Activation Code" />
                    </div>

                    <div class="select_password">
                        <input
                            v-model="password"
                            required
                            autocomplete="new-password"
                            type="password"
                            placeholder="Choose a password (atleast 8 characters)"
                        />
                    </div>
                    <div class="confirm_password">
                        <input v-model="password2" required autocomplete="new-password" type="password" placeholder="Confirm password" />
                    </div>
                    <div class="btn btn_color ta_center">
                        <button type="submit">Submit</button>
                    </div>
                </form>
            </div>
            <div class="container_black">
                <Footer />
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.page_wrap {
    background-repeat: no-repeat;
    height: 100vh;
    .buidl_wrap {
        margin: 6rem auto;
        width: 30rem;
        padding: 5rem 2rem;
        gap: 2rem 0px;
        background: #ffffff;
        box-shadow: 0px 0px 60px rgba(53, 52, 52, 0.25);
        border-radius: 16px;
        @include media("<phone-tab") {
            width: 95%;
            gap: 1rem 0px;
            padding: 2rem;
            margin: 7rem auto;
        }
    }
    .redirect_cont {
        position: absolute;
        left: 0px;
        right: 0px;
        top: 0px;
        bottom: 0px;
        backdrop-filter: blur(3px) brightness(20%) grayscale(10%) opacity(100%);
        .redirect_home {
            margin: 11rem auto !important;
        }
    }
}
</style>
